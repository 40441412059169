import React, { useContext, useEffect } from 'react';
import { Context } from '..';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoadingSpinner from '../shared/ui/loader';
import { routesLogged, routesDefault } from './router';
import Header from '../widgets/header';

const App = () => {
  const { user } = useContext(Context);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      //user.checkAuth();
    }
  }, [user]);

  if (user.isLoading) {
    return <LoadingSpinner />;
  }

  const routerDefault = createBrowserRouter(routesDefault);
  const routerLogged = createBrowserRouter([...routesDefault, ...routesLogged]);

  if (!user.isLogged) {
    return (
      <>
        <Header />
        <RouterProvider router={routerDefault} />
      </>
    );
  }

  return (
    <>
      <Header />
      <RouterProvider router={routerLogged} />
    </>
  );
};

export default observer(App);