import { observer } from "mobx-react-lite";
import AuthForm from "../../entities/authForm";

const WidgetAuth: React.FC = () => {
    return(
        <div style={{ display: 'flex', flexDirection: 'column', gap: '50px', justifyContent: 'center', alignItems: 'center', paddingTop: '10%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', gap: '10px', alignItems: 'center' }}>
          <img src='/img/b1-logo-40px.png' style={{width:'40px'}}/>
          <span style={{ textTransform: 'uppercase', fontSize: 24 }}>Б1 ИБП Мастер: Дорожная карта</span>
        </div>
        <AuthForm />
      </div>
    )
}

export default observer(WidgetAuth);