import { IRoadmapElement } from './rmElements';

export const IBPMasterRoadmapJSON: IRoadmapElement[] = [
  {
    date: '01.08.24',
    section: 'Статистическое прогнозирование',
    id: 'FCST',
    type: 'Запланировано',
    description: 'Базовые методы статистического расчета',
    process: ['Планирование спроса', 'Финансовое планирование'],
    technology: ['Прогнозирование'],
  },
  {
    date: '01.11.24',
    section: 'Статистическое прогнозирование',
    id: 'FCST_ENHANCEMENT',
    type: 'Запланировано',
    description: 'Улучшение методов статистического прогноза',
    process: ['Планирование спроса', 'Финансовое планирование'],
    technology: ['Прогнозирование'],
  },
  {
    date: '31.12.24',
    section: 'Модель данных',
    id: 'MODELEXPORT',
    type: 'Запланировано',
    description: 'Экспорт настроек модели данных',
    process: ['Администрирование'],
    technology: ['Модель данных'],
  },
  {
    date: '31.12.24',
    section: 'Модель данных',
    id: 'MODELECHANGEHISTORY',
    type: 'Запланировано',
    description: 'Расширенная административная информация по изменению модели данных',
    process: ['Администрирование'],
    technology: ['Модель данных'],
  },
  // {
  //   date: '01.01.25',
  //   section: 'Модель данных',
  //   id: 'MODELECHANGEHISTORY',
  //   type: 'Запланировано',
  //   description: '',
  //   process: ['Администрирование'],
  //   technology: ['Модель данных'],
  // },
  {
    date: '31.12.24',
    section: 'Модель данных',
    id: 'MODELATTRASSIGN',
    type: 'Запланировано',
    description: 'Настройка операторов копирования для модели данных',
    process: ['Администрирование'],
    technology: ['Модель данных'],
  },
  {
    date: '01.01.25',
    section: 'Модель данных',
    id: 'MODELATTRASSIGN',
    type: 'Запланировано',
    description: 'Настройка конверсии единиц измерения в модели данных',
    process: ['Администрирование'],
    technology: ['Модель данных'],
  },
  {
    date: 'Видение',
    section: 'Модель данных',
    id: 'MODELATTRASSIGN',
    type: 'Видение',
    description: 'Интеллектуальное меню привязки атрибутов к модели планирования',
    process: ['Администрирование'],
    technology: ['Модель данных'],
  },
  {
    date: 'Видение',
    section: 'Модель данных',
    id: 'MODELATTRASSIGN',
    type: 'Видение',
    description: 'Интеллектуальные подсказки при создании и изменении уровней планирования',
    process: ['Администрирование'],
    technology: ['Модель данных'],
  },
  {
    date: 'Видение',
    section: 'Модель данных',
    id: 'MODELATTRASSIGN',
    type: 'Видение',
    description: 'Обновленный конструктор формул показателей',
    process: ['Администрирование'],
    technology: ['Модель данных'],
  },
  {
    date: 'Видение',
    section: 'Модель данных',
    id: 'DICTIONARYEDIT',
    type: 'Видение',
    description: 'Интерфейс редактирования состава словарей',
    process: ['Администрирование'],
    technology: ['Модель данных'],
  },
  {
    date: 'Видение',
    section: 'Просмотр данных',
    id: 'DATAVIEW_IMPORT',
    type: 'Видение',
    description: 'Импорт данных из XLSX/CSV при просмотре данных',
    process: ['Администрирование'],
    technology: ['Управление данными'],
  },
  {
    date: 'Видение',
    section: 'Просмотр данных',
    id: 'DATAVIEW_IMPORT',
    type: 'Видение',
    description: 'Возможность сохранения фильтров данных и поделиться с пользователями, группами и ролями',
    process: ['Администрирование'],
    technology: ['Управление данными'],
  },
  {
    date: 'Видение',
    section: 'Управление пользователями',
    id: 'ROLE_MANAGEMENT',
    type: 'Видение',
    description: 'Конструктор ролей через присваивание каталогов разрешений',
    process: ['Администрирование'],
    technology: ['Управление пользователями'],
  },
  {
    date: 'Видение',
    section: 'Управление пользователями',
    id: 'FILTER_MANAGEMENT',
    type: 'Видение',
    description: 'Конструктор полномочий через присваивание фильтров разрешений',
    process: ['Администрирование'],
    technology: ['Управление пользователями'],
  },
  {
    date: 'Видение',
    section: 'Управление пользователями',
    id: 'USER_GROUP',
    type: 'Видение',
    description: 'Конструктор групп пользователей',
    process: ['Администрирование'],
    technology: ['Управление данными'],
  },
  {
    date: 'Видение',
    section: 'Логирование',
    id: 'LOGGING',
    type: 'Видение',
    description: 'Объединенное приложение логов с детальной информацией',
    process: ['Администрирование'],
    technology: ['Управление логированием'],
  },
  {
    date: 'Видение',
    section: 'Интеграция',
    id: 'AGENT_STATUS',
    type: 'Видение',
    description: 'Просмотр информации по агенту интеграции',
    process: ['Администрирование'],
    technology: ['Управление интеграцией'],
  },
  {
    date: 'Видение',
    section: 'Интеграция',
    id: 'FLOW_PREVIEW',
    type: 'Видение',
    description: 'Предпросмотр значений в ноде потока',
    process: ['Администрирование'],
    technology: ['Управление интеграцией'],
  },
  {
    date: 'Видение',
    section: 'Интеграция',
    id: 'INTEGRATION_MSSQL',
    type: 'Видение',
    description: 'Нода: Microsoft SQL',
    process: ['Администрирование'],
    technology: ['Управление интеграцией'],
  },
  {
    date: 'Видение',
    section: 'Интеграция',
    id: 'INTEGRATION_SCHEDULING',
    type: 'Видение',
    description: 'Конструктор периодического и отложенного запуска потоков',
    process: ['Администрирование'],
    technology: ['Управление интеграцией'],
  },
  {
    date: 'Видение',
    section: 'Интеграция',
    id: 'ALERT_MGMT',
    type: 'Видение',
    description: 'Конструктор алертов',
    process: ['Администрирование'],
    technology: ['Управление данными'],
  },
  {
    date: 'Видение',
    section: 'Интеграция',
    id: 'FLOW_JSON',
    type: 'Видение',
    description: 'Возможность просмотра и редактирования потока в формате JSON',
    process: ['Администрирование'],
    technology: ['Управление интеграцией'],
  },
];


// {
//   date: '01.01.23',
//   section: 'Статистический прогноз',
//   id: 'FCST',
//   type: 'Запланировано',
//   description: 'LOREEEEEEEEEm',
//   process: 'Планирование спроса',
//   technology: 'Прогнозирование',
// },