import { Col } from 'antd';

interface ILearningBlock {
  learningType: string;
  learningName: string;
  learningCode: string;
  learningMethod: string;
  learningTime: string;
  learningPicURI?: string;
}

const LearningBlock: React.FC<ILearningBlock> = ({ learningType, learningName, learningCode, learningMethod, learningTime, learningPicURI }) => {
  return (
    <Col
      style={{
        width: '330px',
        height: '280px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: '0px 0px 10px 1px lightgrey',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
        <span style={{ fontSize: '14px', color: '#727272' }}>{learningType}</span>
        <span style={{ color: '#333', fontWeight: 700, fontSize: '16px', height: 44 }}>{learningName}</span>
      </div>
      <div style={{marginBottom: '10px'}}>
        <span style={{ color: '#727272', fontSize: '14px', padding: '10px' }}>
          {learningCode} · {learningMethod} · {learningTime}
        </span>
      </div>
      <div style={{ width: '330px' }}>
        <img width={'330px'} src={learningPicURI ? learningPicURI : 'https://help.sap.com/learning-journeys/static/img/course_tile_picture.afe2a2bb.svg'} />
      </div>
    </Col>
  );
};

interface IlearningBlocks {
  name: string;
  description: string;
  blocks: JSX.Element[];
}

export const learningBlocks: IlearningBlocks[] = [
  {
    name: 'Начать с основ',
    description: 'Вы новичок в теме или решении? Здесь Вы найдете информацию, которая поможет получить основное представление.',
    blocks: [
      <LearningBlock
        learningName='Б1 ИБП Мастер, Обзор'
        learningCode='PX1000'
        learningType='Курс'
        learningMethod='E-Reading'
        learningTime='1ч'
        learningPicURI='/img/photos/5287591821188392263.jpg'
      />,
      <LearningBlock
        learningName='Основные инструменты и особенности ИБП Мастер'
        learningCode='PX1100'
        learningType='Курс'
        learningMethod='E-Reading'
        learningTime='1ч'
        learningPicURI='/img/photos/5287591821188392272.jpg'
      />,
      <LearningBlock
        learningName='Использование связки Knowledge Space и ИБП Мастер'
        learningCode='PX1200'
        learningType='Курс'
        learningMethod='E-Reading'
        learningTime='1ч'
        learningPicURI='/img/photos/5287591821188392286.jpg'
      />,
    ],
  },
  {
    name: 'Стать компетентным',
    description: 'Эти темы помогут Вам получить глубокие знания о продукте и помогут в получении сертификации.',
    blocks: [
      <LearningBlock
        learningName='Управление моделями данных в ИБП Мастер'
        learningCode='PX2100'
        learningType='Курс'
        learningMethod='E-Reading'
        learningTime='2ч'
        learningPicURI='/img/photos/5287591821188392281.jpg'
      />,
      <LearningBlock
        learningName='Управление мастер данными в ИБП Мастер'
        learningCode='PX2200'
        learningType='Курс'
        learningMethod='E-Reading'
        learningTime='2ч'
        learningPicURI='/img/photos/5287591821188392276.jpg'
      />,
      <LearningBlock
        learningName='Управление показателями в ИБП Мастер'
        learningCode='PX2300'
        learningType='Курс'
        learningMethod='E-Reading'
        learningTime='2ч'
        learningPicURI='/img/photos/5287591821188392284.jpg'
      />,
      <LearningBlock
        learningName='Управление трансформациями данных в ИБП Мастер'
        learningCode='PX2400'
        learningType='Курс'
        learningMethod='E-Reading'
        learningTime='4ч'
        learningPicURI='/img/photos/5287591821188392288.jpg'
      />,
      <LearningBlock
        learningName='Сертификация "Моделист в ИБП Мастер"'
        learningCode='PX2400'
        learningType='Сертификация'
        learningMethod='E-Reading'
        learningTime='1.5ч'
        learningPicURI='/img/photos/5287591821188392275.jpg'
      />,
    ],
  },
];
