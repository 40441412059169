import { Collapse, CollapseProps, Empty, Input, Select, Space } from 'antd';
import B1Input from '../../shared/ui/input';
import { CSSProperties, ReactElement, useEffect, useRef, useState } from 'react';
import { IRoadmapElement } from './rmElements';
import RoadmapStage, { IRoadmapStage } from './rmStage';
import moment from 'moment';
import { IBPMasterRoadmapJSON } from './elements';
import Fuse from 'fuse.js';

const PageRoadmap: React.FC = () => {
  const [rawRoadmapComponents, setRawRoadmapComponents] = useState<IRoadmapElement[]>(IBPMasterRoadmapJSON);
  const [roadmapComponents, setRoadmapComponents] = useState<ReactElement[]>([]);
  const currentElementRef = useRef<HTMLDivElement>(null);

  const [searchFilter, setSearchFilter] = useState<string>('');

  const emptyArr: string[] = [];
  const [processFilter, setProcessFilter] = useState<string[]>(
    IBPMasterRoadmapJSON.reduce((acc, curr) => [...acc, ...curr.process], emptyArr).filter((value, index, array) => array.indexOf(value) === index)
  );
  const [technologyFilter, setTechnologyFilter] = useState<string[]>(
    IBPMasterRoadmapJSON.reduce((acc, curr) => [...acc, ...curr.technology], emptyArr).filter((value, index, array) => array.indexOf(value) === index)
  );

  useEffect(() => {
    const _elements: { [key: string]: Array<IRoadmapElement> } = {};

    for (const el of rawRoadmapComponents) {
      let stageName: string;

      if (el.date === 'Видение') {
        stageName = el.date;
      } else {
        const date = moment(el.date, 'DD.MM.YYYY');

        stageName = `${date.quarter()}.${date.year()}`;
      }

      if (!_elements[stageName]) {
        _elements[stageName] = [];
      }

      _elements[stageName] = [..._elements[stageName], el];
    }

    const _dates = Object.keys(_elements);
    const _items: ReactElement[] = [];

    for (const period of _quarters) {
      let type: 'Default' | 'Current' | 'Vision' = 'Default';

      if (period === 'Видение') {
        type = 'Vision';

        _items.push(
          <RoadmapStage
            key={period}
            name={period.toUpperCase()}
            elements={_elements[period] ? _elements[period] : []}
            type={type}
          />
        );
      } else {
        const _date_split = period.split('.');

        let focus: CSSProperties = {};

        if (Number(_date_split[0]) === moment().quarter() && Number(_date_split[1]) === moment().year()) {
          type = 'Current';
        }

        _items.push(
          <RoadmapStage
            key={period}
            name={`Q${_date_split[0]} ${_date_split[1]}`}
            elements={_elements[period] ? _elements[period] : []}
            type={type}
            reference={type === 'Current' ? currentElementRef : null}
          />
        );
      }
    }

    setRoadmapComponents(_items);
  }, [rawRoadmapComponents]);

  const onFilterApply = () => {
    return IBPMasterRoadmapJSON.filter((el) => {
      if (processFilter && technologyFilter) {
        let processBool: boolean = false;
        let technologyBool: boolean = false;

        for (const proc of el.process) {
          if (processFilter.includes(proc)) {
            processBool = true;
          }
        }

        for (const tech of el.technology) {
          if (technologyFilter.includes(tech)) {
            technologyBool = true;
          }
        }

        return processBool && technologyBool ? true : false;
      }
    });
  };

  useEffect(() => {
    setRawRoadmapComponents(onFilterApply());
  }, [processFilter, technologyFilter]);

  const onChangeFilter = (value: React.ChangeEvent<HTMLInputElement>) => {
    const elements = onFilterApply();

    setSearchFilter(value.target.value);

    setRawRoadmapComponents(() => {
      if (!value.target.value) {
        return elements;
      }
      const fuse = new Fuse(elements, { keys: ['section', 'description'] });
      return fuse
        .search(value.target.value)
        .map((el) => el.item)
        .sort();
    });
  };

  useEffect(() => {
    if (currentElementRef.current) {
      currentElementRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [rawRoadmapComponents]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 110px)', padding: '15px' }}>
      <Space
        size={'large'}
        wrap
      >
        <Input
          placeholder='Поиск по ключевым словам'
          size='large'
          style={{ width: '50vw', minWidth: '230px' }}
          value={searchFilter}
          onChange={onChangeFilter}
        />

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          {/* <span style={{ fontSize: '12px' }}>Процессы</span> */}
          <Select
            size='large'
            mode='multiple'
            options={IBPMasterRoadmapJSON.reduce((acc, curr) => [...acc, ...curr.process], emptyArr)
              .filter((value, index, array) => array.indexOf(value) === index)
              .map((el) => ({ label: el, value: el }))}
            //[...new Set(myArray)]
            value={processFilter}
            maxTagCount='responsive'
            style={{ width: '20vw', minWidth: '230px', fontSize: '14px' }}
            placeholder='Процесс'
            onChange={(el) => setProcessFilter(el)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          {/* <span style={{ fontSize: '12px' }}>Функциональные блоки</span> */}
          <Select
            size='large'
            mode='multiple'
            options={IBPMasterRoadmapJSON.reduce((acc, curr) => [...acc, ...curr.technology], emptyArr)
              .filter((value, index, array) => array.indexOf(value) === index)
              .map((el) => ({ label: el, value: el }))}
            value={technologyFilter}
            maxTagCount='responsive'
            style={{ width: '20vw', minWidth: '230px', fontSize: '14px' }}
            placeholder='Технология'
            onChange={(el) => setTechnologyFilter(el)}
          />
        </div>
      </Space>
      <div
        style={{
          background: '#fafafa',
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
          paddingTop: '30px',
          paddingLeft: '0px',
          gap: '20px',

          overflowX: 'auto',
        }}
      >
        {roadmapComponents.length > 0 ? roadmapComponents : <Empty description='Нет элементов роадмапа по заданным параметрам' />}
      </div>
    </div>
  );
};

export default PageRoadmap;

const optionsProcesses = [
  {
    label: 'Demand Plannig',
    value: 'Планирование спроса',
  },
  {
    label: 'Supply Planning',
    value: 'Планирование поставок',
  },
];

const optionsTechnologies = [
  {
    label: 'Прогнозирование',
    value: 'Прогнозирование',
  },
];

const _quarters = ['1.2024', '2.2024', '3.2024', '4.2024', '1.2025', 'Видение'];
