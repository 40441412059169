import { Collapse, CollapseProps } from 'antd';
import { useEffect, useState } from 'react';
import RoadmapElement, { IRoadmapElement } from './rmElements';

export interface IRoadmapStage {
  name: string;
  elements: Array<IRoadmapElement>;
  type: 'Default' | 'Current' | 'Vision';
  reference?: React.RefObject<HTMLDivElement> | null
}

const RoadmapStage: React.FC<IRoadmapStage> = ({ name, elements, type, reference = null }) => {
  const [roadmapStageitems, setRoadmapStageItems] = useState<CollapseProps['items']>([]);

  useEffect(() => {
    const _elements: { [key: string]: Array<IRoadmapElement> } = {};
    for (const el of elements) {
      if (!_elements[el.section]) {
        _elements[el.section] = [];
      }

      _elements[el.section] = [..._elements[el.section], el];
    }

    const _sections = Object.keys(_elements);
    const _items: CollapseProps['items'] = [];

    for (const section of _sections) {
      _items.push({
        key: section,
        label: <span style={{ fontWeight: 500 }}>{section}</span>,
        children: (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            {_elements[section].map((el, ind) => (
              <RoadmapElement
                key={ind}
                {...el}
              />
            ))}
          </div>
        ),
        style: panelStyle,
      });
    }

    setRoadmapStageItems(_items);
  }, [elements]);

  return (
    <div
      style={{
        background: '#F5F6F7',
        width: '362px',
        height: '100%',
        minHeight: '500px',
        minWidth: '362px',
        boxShadow: '0px 0px 10px 1px lightgrey',
        border: '1px solid #A9B4BE',
        borderRadius: '0.75rem',

        boxSizing: 'border-box',

        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
      }}
      ref={reference}
    >
      <div
        style={{
          marginLeft: -1,
          width: '362px',
          height: '10px',
          background: type === 'Current' ? '#F39200' : 'none',
          borderRadius: '0.75rem 0.75rem 0 0',
          zIndex: 0,
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '0px 16px 0px 16px',
          boxSizing: 'border-box',
          zIndex: 1,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
          <p style={{ fontWeight: 700 }}>{name}</p>
          <div
            style={{
              background: '#73C480',
              borderRadius: '40%',
              width: '25px',
              height: '15px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              fontWeight: 500,
            }}
          >
            {roadmapStageitems?.reduce((acc, curr: any) => acc + curr?.children?.props?.children.length, 0)}
          </div>
        </div>
        <div>{/* <p>Сгруппировать</p> */}</div>
      </div>
      {roadmapStageitems && roadmapStageitems?.length > 0 && (
        <Collapse
          defaultActiveKey={roadmapStageitems?.map((el: any) => el.key)}
          bordered={false}
          items={roadmapStageitems}
          style={{ border: 'none', padding: 0, margin: 0, height: '90%', minHeight: '300px', overflowY: 'scroll', overflowX: 'hidden' }}
        />
      )}
    </div>
  );
};

export default RoadmapStage;

const panelStyle: React.CSSProperties = {
  background: '#F5F6F7',
  border: 'none',
};
