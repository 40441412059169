import { RouteObject } from "react-router-dom"
import PageAuth from "../../pages/auth/index";
import PageHome from "../../pages/home";
import PageError404 from "../../pages/error404";
import PageError500 from "../../pages/error500";
import PageLearningPath from "../../pages/learningPath";
import PageRoadmap from "../../pages/roadmap";

export const routesDefault: RouteObject[] = [
    {
      path: '/',
      element: <PageRoadmap />,
      errorElement: <PageError404 />,
    },
    {
      path: '/roadmap',
      element: <PageRoadmap />,
      errorElement: <PageError500 />
    },
    {
      path: '/learning',
      element: <PageLearningPath />,
      errorElement: <PageError500 />
    }
]

export const routesLogged: RouteObject[] = [
  {
    path: '/profile',
    element: <PageAuth />,
    errorElement: <PageError500 />
  }
]