import React from 'react';
import styled, { keyframes } from 'styled-components';

const colors = ['#37AB49', '#2C893A', '#5FBC6D'];

const changeColor = keyframes`
  0% { border-color: ${colors[0]}; }
  33% { border-color: ${colors[1]}; }
  66% { border-color: ${colors[2]}; }
  100% { border-color: ${colors[0]}; }
`;

const Line = styled.div`
  width: 5px;
  height: inherit;
  border-left: 3px dashed transparent;
  animation: ${changeColor} 3s infinite;
`;

const ColorChangingLine: React.FC = () => {
  return <Line />;
};

export default ColorChangingLine;
