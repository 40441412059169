import { useState } from 'react';

export interface IRoadmapElement {
  date: string;
  section: string;
  id: string;
  type: 'Видение' | 'Запланировано' | 'Разработка' | 'Тестирование' | 'Реализовано';
  description: string;
  process: Array<string>;
  technology: Array<string>;
}

const _colors = {
  Видение: '#662483',
  Запланировано: '#F39200',
  Разработка: '#E6332A',
  Тестирование: '#00A092',
  Реализовано: '#1D71B8',
};

const RoadmapElement: React.FC<IRoadmapElement> = ({ type, description }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      style={{
        width: '320px',
        height: '90px',
        background: 'white',
        borderRadius: '0.5rem',
        border: hover ? '2px solid #37AB49' : '2px solid #FFF',
        padding: '15px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        boxShadow: '0px 0px 10px 1px lightgrey',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '8px', alignItems: 'center' }}>
        <div style={{ height: '20px', width: '8px', background: _colors[type], borderRadius: '0px 35% 35% 0px' }}></div>
        <span style={{ textTransform: 'uppercase', fontWeight: 500, fontSize: '11px', color: '#5B738B' }}>{type}</span>
      </div>
      <span
        style={{
          padding: 0,
          margin: 0,

          paddingRight: '15px',

          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',

          height: '45px',
          width: '300px',

          fontWeight: 500,
          fontSize: '12px',

          overflow: 'hidden',
          textOverflow: 'ellipsis !important',

          lineHeight: 1.4,
        }}
      >
        {description}
      </span>
    </div>
  );
};

export default RoadmapElement;
