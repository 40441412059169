import { Row } from 'antd';
import { learningBlocks } from './learningBlock';
import { LearningSector } from './learningSector';
import ColorChangingLine from './util/line';

const PageLearningPath: React.FC = () => {
  return (
    <div style={{ padding: '15px', display: 'flex', flexDirection: 'row' }}>
      <ColorChangingLine />
      <div style={{ transform: 'scale(1)', width: '100%', display: 'flex', flexDirection: 'column', gap: '45px', marginLeft: '-18px' }}>
        {learningBlocks.map((el) => (
          <LearningSector
            LearningBlockName={el.name}
            LearningBlockDesc={el.description}
            LearningBlocks={el.blocks}
          />
        ))}
      </div>
    </div>
  );
};

export default PageLearningPath;
