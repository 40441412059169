import { Row } from "antd";

interface ILearningSector {
    LearningBlockName: string;
    LearningBlockDesc: string;
    LearningBlocks: JSX.Element[];
  }
  
export const LearningSector: React.FC<ILearningSector> = ({ LearningBlockDesc, LearningBlockName, LearningBlocks }) => {
    return (
      <li style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
          <img
            src={'/img/svg/place.svg'}
            height={32}
            style={{ cursor: 'pointer', backgroundColor: 'white' }}
          />
          <h3 style={{ fontWeight: 500, fontSize: '27px', lineHeight: '1.3em', margin: 0, padding: 0 }}>{LearningBlockName}</h3>
        </div>
        <div style={{marginLeft: '40px'}}>
          <p style={{ fontSize: '16px', lineHeight: '1.3em' }}>{LearningBlockDesc}</p>
          <div>
            <Row style={{ listStyle: 'none', gap: '40px' }}>{LearningBlocks}</Row>
          </div>
        </div>
      </li>
    );
  };